@import "./reset";
@import "./base";

body,
html {
  width: 100%;
  height: 100%;
  // background-color: #f7faff !important;
  box-sizing: border-box;
}








/* 隐藏滚动条 */
// ::-webkit-scrollbar {
//   display: none;
//   width: 0;
//   height: 0;
//   color: transparent;
// }