.footer {
  // width: 1200px;
  height: 305px;
  margin: 0 auto;
  background: #212631;
  padding: 0;
  display: flex;
  flex-direction: column;

}

.footerTop {
  width: 1200px;
  height: 245px;
  box-sizing: border-box;
  padding-top: 20px;
  margin: 0 auto;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;

}

.logoWrap {
  img {
    display: block;
    width: 290px;
    height: 45px;
  }
}

.links {
  display: flex;
  justify-content: space-between;
  gap: 60px;

  a {
    font-size: 16px;
    color: rgba(255, 255, 255, 0.88);

  }
}

.addr {
  color: #999;
  font-size: 12px;
}

.footerBottom {
  flex: 1 0 60px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-top: 1px solid rgba(151, 151, 151, 0.2);
  color: #999;
  text-align: center;
}