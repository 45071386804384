.header {
  width: 1200px;
  height: 110px;
  margin: 0 auto;
  background: #fff;
  padding: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;

  .logoWrap {
    cursor: pointer;

    img {
      display: block;
      width: 350px;
      height: 60px;
    }
  }

  .menusWrap {
    :global {

      .ant-menu-item {
        display: flex;
        align-items: center;

        .ant-menu-title-content {
          margin-inline-start: 5px;
          // margin-left: 5px;
        }
      }

      .ant-menu-horizontal {
        border-bottom: none;
      }


    }


  }
}